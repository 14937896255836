import { Component, OnInit } from '@angular/core';
import Typed from 'typed.js';

@Component({
  selector: 'app-opening',
  templateUrl: './opening.component.html',
  styleUrls: ['./opening.component.scss'],
})
export class OpeningComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    const options = {
      strings: ['designer.', 'developer.', 'creative nerd.'],
      typeSpeed: 80,
      backSpeed: 50,
      showCursor: false,
      // contentType: undefined,
      // autoInsertCss: false,
      cursorChar: '|',
      loop: false,
      smartBackspace: false,
      // onComplete: function (instance: any) {
      //   instance.cursor.remove();
      // },
    };

    const typed = new Typed('.typewriter', options);
  }
}
